import React, { useState, useCallback, useEffect, useRef } from "react";
import useStyles from "./useStyles";
// Material Components
import { Container, Grid, IconButton, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Material Icons
import { Close as CloseIcon, ArrowBack as ArrowBackIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from 'components/hooks';
// Screens
import TextBoxContentDefinition from "pages/post/posted/contentManagement/contentDetailDefinition/textBoxContentDefinition";
// Components
import TextBoxContentContainer from "components/textBoxContentContainer";
import Dialog from "components/dialog";
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

import { TextProvider } from './HtmlContentContext';

import { addImageToContent } from "utils/apiCalls/contents";
import NinovienEditorViewer from "./NinovienEditorViewer";

interface Props {
  onClose: () => void;
  contentId: any;
  isReadonly?: boolean;
  customActions?: any;
}

const ContentDetailDefinition: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute, showWarningMessage } = useCustomHooks();
  const classes = useStyles();
  const childRef = useRef(null);

  // Variables
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [content, setContent] = useState<any>();
  const [pureHtmlContent, setPureHtmlContent] = useState<any>();
  const [contentDetail, setContentDetail] = useState<any>();
  const [openTextBoxContentDefinitionAsDialog, setOpenTextBoxContentDefinitionAsDialog] = useState<boolean>(false);
  const [textboxContent, setTextboxContent] = useState<any>();

  const getSrcList = (html: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const imgElements = doc.querySelectorAll('img');
    const srcList = Array.from(imgElements).map(img => img.src);
    return srcList;
  }

  const setHtmlContentToStorage = (html) => {
    localStorage.setItem('htmlContent', html);
  }

  const getHtmlContentFromStorage = () => {
    return localStorage.getItem('htmlContent') || '';
  }

  const getJsonObject = (contentDetail: any) => {
    try {
      return JSON.parse(contentDetail);
    } catch (e) {
      return contentDetail;
    }
  }

  useEffect(() => {
    if (content) return;
    var id = props.contentId;
    proxyExecute(`Contents/WithDetail/${id}`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => {
        setContent(data);
        setContentDetail(getJsonObject(data.detail));
        //setHtmlContentToStorage(data.detail);
        setPureHtmlContent(data.detail);
      }
    });
  });

  const getRightHeader = () => {
    return (
      <Grid container justifyContent="center" spacing={1}>
        <Grid item>
          {!props.isReadonly && content.contentTypeId !== 'c3ef221b-5672-44ca-94c7-b04301435512' &&
            <LoadingButton size="large" variant="contained" onClick={() => {
              saveContent();
            }} loading={isLoading}>
              {t('Save')}
            </LoadingButton>}
          {props.customActions}
        </Grid>
      </Grid>
    );
  }

  const saveContent = () => {
    setIsLoading(true);
    triggerSaveAction();
  }

  const closeIcon = () => {
    return (
      <IconButton color="inherit" onClick={props.onClose} className={classes.closeIcon}>
        {props.customActions ? <CloseIcon /> : <ArrowBackIcon />}
      </IconButton>
    );
  };

  const triggerSaveAction = () => {
    if (childRef.current && childRef.current.triggerGetHtml) {
      childRef.current.triggerGetHtml();
    } else {
      console.log("Child component or method not available yet.");
      alert("Child component is not ready yet!");
    }
  };

  const handleSaveAction = (html: any) => {
    setHtmlContentToStorage(html);
    callSaveService();
  }

  const getFileLinks = (htmlString: any) => {
    let fileLinks = [];

    // Parse the HTML string
    let parser = new DOMParser();
    let doc = parser.parseFromString(htmlString, "text/html");

    // Select all <a> tags with class "kg-file-card-container"
    doc.querySelectorAll('.kg-file-card-container').forEach(anchor => {
      let href = anchor.getAttribute('href'); // Get the href value

      // Find the nearest file name inside the same kg-file-card
      let fileNameElement = anchor.closest('.kg-file-card')?.querySelector('.kg-file-card-filename');
      let fileName = fileNameElement ? fileNameElement.textContent.trim() : 'Unknown';

      fileLinks.push({ href, fileName });
    });

    return fileLinks;
  }

  const callSaveService = async () => {
    var imageList = getSrcList(getHtmlContentFromStorage());
    var unsavedImageCount = imageList.filter(x => x.startsWith('blob')).length;

    if (unsavedImageCount > 0) {
      imageList.map((blobUrl: any) => {
        saveContentImage(blobUrl);
      });
    }

    var fileList = getFileLinks(getHtmlContentFromStorage());
    var unsavedFileCount = fileList.filter(x => x.href.startsWith('blob')).length;

    if (unsavedFileCount > 0) {
      fileList.map((file: any) => {
        saveContentFile(file);
      });
    }
    // else {
    //   saveUpdatedContent();
    // }
    checkAllItemsSaved();
  }

  const saveUpdatedContent = () => {
    var newImageList = getSrcList(getHtmlContentFromStorage());
    var imageGuidList: any[] = [];
    if (newImageList.length > 0) {
      newImageList.map((bUrl: any, index: any) => {
        imageGuidList.push(bUrl.split('/').pop().split('.')[0]);
      });
    }

    var newFileList = getFileLinks(getHtmlContentFromStorage());
    if (newFileList.length > 0) {
      newFileList.map((href: any, index: any) => {
        imageGuidList.push(href.href.split('/').pop().split('.')[0]);
      });
    }

    var id = props.contentId;
    proxyExecute(`Contents/Detail/${id}`, HttpMethod.Put, {
      body: { detail: getHtmlContentFromStorage(), imageGuidList },
      setIsLoading,
      callback: () => {
        //setHtmlContentToStorage('');
      }
    });
  }

  const checkAllItemsSaved = () => {
    var htmlContent = getHtmlContentFromStorage();

    var fileList = getFileLinks(htmlContent);
    var unsavedFileCount = fileList.filter(x => x.href.startsWith('blob')).length;

    var imageList = getSrcList(htmlContent);
    var unsavedImageCount = imageList.filter(x => x.startsWith('blob')).length;

    if (unsavedImageCount == 0 && unsavedFileCount == 0) {
      saveUpdatedContent();
    }
  }

  const saveContentImage = useCallback(async (blobUrl: any) => {
    var imageName = blobUrl.split('/').pop() + '.jpeg';
    const response = await fetch(blobUrl);
    const blob = await response.blob();

    const reader = new FileReader();

    reader.onload = async () => {
      const base64String = reader?.result;

      const image = new Image();
      image.onload = async () => {
        const maxWidth = 1800;
        const maxHeight = 4000;

        let width = image.width;
        let height = image.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx?.drawImage(image, 0, 0, width, height);

        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);

        var newImageUrl = await addImageToContent(props.contentId, blobUrl, imageName, compressedBase64);

        var htmlContent = getHtmlContentFromStorage();
        htmlContent = htmlContent.replace(blobUrl, newImageUrl);
        setHtmlContentToStorage(htmlContent);

        checkAllItemsSaved();
      };

      image.src = "" + base64String;
    };

    reader.readAsDataURL(blob);
  }, [isLoading]);

  const saveContentFile = useCallback(async (file: any) => {
    var fileName = file.fileName;
    const response = await fetch(file.href);
    const blob = await response.blob();

    const reader = new FileReader();

    reader.onload = async () => {
      const base64String = reader?.result;
      var newFileUrl = await addImageToContent(props.contentId, file.href, fileName, base64String);

      var htmlContent = getHtmlContentFromStorage();
      htmlContent = htmlContent.replace(file.href, newFileUrl);
      setHtmlContentToStorage(htmlContent);
      checkAllItemsSaved();
    };

    reader.readAsDataURL(blob);
  }, [isLoading]);

  if (!content) return null;

  return (
    <Dialog
      open
      fullScreen={!props.customActions}
      closable={false}
      titleTextAlign="left"
      onClose={props.onClose}
      leftHeader={closeIcon()}
      rightHeader={getRightHeader()}
      showAlwaysTopDivider={true}
      title={content.title}
    >
      <Container
        maxWidth="md"
        disableGutters
        classes={{
          maxWidthMd: classes.maxWidthMd,
        }}
        style={{ padding: '32px' }}
      >
        {content.contentChapter &&
          content.contentChapter.code !== 'header-chapter' &&
          <Typography style={{
            marginBottom: '10px', fontSize: '16px', fontWeight: 700,
            color: "#4F525B"
          }}>{content.contentChapter.name.toLocaleUpperCase('tr-TR')}</Typography>}

        <Typography style={{ fontSize: '44px', lineHeight: '50px', fontWeight: '800', marginLeft: '-2px' }}>{content.title}</Typography>

        {content.showWriter &&
          content.writers &&
          content.writers.length > 0 &&
          <Typography variant="body1" style={{ marginTop: '10px' }}>
            {content.writers.map((writer: any, index: number) => {
              return writer.name + (index === content.writers.length - 1 ? '' : ', ')
            })}
          </Typography>
        }
        <Grid container style={{ marginTop: '50px' }}>
          {content.contentTypeId !== 'c3ef221b-5672-44ca-94c7-b04301435512' && <Grid item xs={12}>
            <TextProvider>
              <NinovienEditorViewer isReadonly={props.isReadonly} htmlContent={pureHtmlContent} ref={childRef} setHtmlToParent={handleSaveAction}></NinovienEditorViewer>
            </TextProvider>
          </Grid>}
          {content.contentTypeId === 'c3ef221b-5672-44ca-94c7-b04301435512' && <Grid item xs={12}>
            {!props.isReadonly &&
              <LoadingButton size="large" variant="contained" onClick={() => {
                setTextboxContent(undefined);
                setOpenTextBoxContentDefinitionAsDialog(true);
              }} loading={isLoading}>
                {t('Add Text Box')}
              </LoadingButton>}

            <Grid style={{ marginRight: -16 }}>
              <TextBoxContentContainer isReadOnly={props.isReadonly} contentDetail={contentDetail} onDeleteClick={(id: any) => {

                var newContentDetail = contentDetail.filter((item: any) => item.id !== id);
                setContentDetail(newContentDetail);

                var id = props.contentId;
                proxyExecute(`Contents/Detail/${id}`, HttpMethod.Put, {
                  body: { detail: JSON.stringify(newContentDetail) },
                  setIsLoading,
                  callback: () => { }
                });
              }}
                onEditClick={(id: any) => {
                  var textboxContentData = contentDetail.find((item: any) => item.id === id);
                  setTextboxContent(textboxContentData);
                  setOpenTextBoxContentDefinitionAsDialog(true);
                }}></TextBoxContentContainer>
            </Grid>
          </Grid>}
          <Grid container justifyContent="center" spacing={1}>
          </Grid>
        </Grid>
      </Container>
      {openTextBoxContentDefinitionAsDialog &&
        <TextBoxContentDefinition contentId={props.contentId} textboxContent={textboxContent}
          onClose={(data) => {
            setOpenTextBoxContentDefinitionAsDialog(false);

            if (!data) return;
            var newContentDetail;
            if (textboxContent) {
              newContentDetail = contentDetail.map((item: any) => {
                if (item.id === data.id) {
                  return data;
                }
                return item;
              });
              setTextboxContent(undefined);
            }
            else {
              newContentDetail = contentDetail;
              if (!newContentDetail)
                newContentDetail = [];
              newContentDetail.push(data);
            }

            setContentDetail(newContentDetail);

            var id = props.contentId;
            proxyExecute(`Contents/Detail/${id}`, HttpMethod.Put, {
              body: { detail: JSON.stringify(newContentDetail) },
              setIsLoading,
              callback: () => { }
            });
          }}></TextBoxContentDefinition>}
    </Dialog>
  );
};

export default ContentDetailDefinition;


