import React, { useEffect, forwardRef, useImperativeHandle } from 'react';
import {
    MemoizedExtendedKoenigEditor,
    useKoenigEditor
} from '@fatih_ergun/koenig-lexical';
import { useText } from './HtmlContentContext'; // Import the custom hook to access context


export const NinovienEditorComponent = forwardRef(({ htmlContent, onGetHtml, onGetText, setHtmlToParent, isReadonly }, ref) => {
    const { getHtml, setHtml } = useKoenigEditor()
    const [htmlOutput, setHtmlOutput] = React.useState()
    const { text, setText } = useText(); // Get and set text from context
    const [isInitialized, setIsInitialized] = React.useState(false);

    useImperativeHandle(ref, () => ({
        triggerGetHtml: tGetHtml
    }));

    const tGetHtml = () => {
        getHtml().then(html => {
            //setHtmlOutput(html)
            setHtmlToParent(html);
            console.log(html + 'Falan Filan Falan Filan')
            //alert(html)
        })
    }

    React.useEffect(() => {
        if (htmlContent && !isInitialized) {
            setHtml(htmlContent);
            setIsInitialized(true);
        }
        if (onGetHtml) {
            onGetHtml(() => getHtml());
        }
    }, [htmlContent, onGetHtml, getHtml, setHtml]);

    useEffect(() => {
        if (onGetText) {
            onGetText(getHtml()); // Notify the parent with the current text on each render
        }
    }, [getHtml, onGetText]);

    useEffect(() => {
        if (text == 'getContext') {
            //alert(text);

            getHtml().then(html => {
                //setHtmlOutput(html)
                setText(html);
                //alert(html)
            })
        }
    }, [text]);

    return (
        <>
            {/* burda koenig-lexical class olmasi lazim */}
            {!isReadonly && <div className="flex flex-1 koenig-lexical h-full" style={{ height: '100vh' }}>
                <MemoizedExtendedKoenigEditor />
            </div>}
            {/* onemli! html'i gostermek istediginiz yerde parent elemente koenig-lexical ve kg-prose classlar asagidaki gibi eklenmelidir  */}
            {isReadonly && <div className="koenig-lexical">
                <div className='kg-prose' dangerouslySetInnerHTML={{ __html: htmlContent }} />
            </div>}
        </>
    );
});