import './NinovienEditorViewer.css';
import {NinovienEditorComponent} from './NinovienEditorComponent'
import React,{useRef,forwardRef, useImperativeHandle} from 'react';

import {fetchEmbed} from './utils/fetchEmbed';

// bu css dosyasi editorun tasarimi icin kullanilir.
import '@fatih_ergun/koenig-lexical/koenig-lexical.css'

// KoenigComposer bir React Context Provider'i olarak calisir ve editorun butun ozelliklerini saglar.
// Bu componenti Editorun disinda eklenmelidir
import {
  KoenigComposer,
} from '@fatih_ergun/koenig-lexical';

// file uploader icin kullanilan hook
import {fileTypes, useFileUpload} from './utils/useFileUpload';
import { useText } from './HtmlContentContext'; // Import the custom hook to access context


const NinovienEditorViewer = forwardRef(({htmlContent,setHtmlToParent, isReadonly},ref) => {
  const { text,setText } = useText(); // Get the current text from context
  //const childRef = useRef(null);

  // bunlar default olarak kullanilan config
  const cardConfig = {
      renderLabels: true,
      siteUrl: window.location.origin,
      membersEnabled: true,
      stripeEnabled: true,
      deprecated: {
          headerV1: true
      },
      feature: {
          collections: false,
          collectionsCard: false,
          contentVisibility: true
      },
      fetchEmbed: fetchEmbed,
  };

  const props = {
      cardConfig,
      darkMode: false,
      fileUploader: {useFileUpload: useFileUpload({isMultiplayer: false}), fileTypes},
      isSnippetsEnabled: false,
      isTKEnabled: true,
  };

  // const handleChildMethod = (html) => {
  //   alert("Callback from child component" + html);
  // }

  // const handleClick = () => {
  //   if (childRef.current && childRef.current.triggerGetHtml) {
  //     childRef.current.triggerGetHtml();
  //   } else {
  //     console.log("Child component or method not available yet.");
  //     alert("Child component is not ready yet!");
  //   }
  // };

  return (
      <div>
        <KoenigComposer {...props}>
          <NinovienEditorComponent ref={ref} htmlContent={htmlContent} setHtmlToParent={setHtmlToParent} isReadonly={isReadonly}/>
        </KoenigComposer>
      </div>
  );
});

export default NinovienEditorViewer;